<template>
  <div data-view>
    <hero-with-image
      title="experience your own journey in Nakhon Pathom"
      preDescription="Nakhon Pathom is one of the oldest cities and the center of civilization in the Dvaravati Kingdom."
      description="<p>a city that is rich in history with more than 100 temples, for example, home to the royal temple, Wat Phra Pathom Chedi Rachavaramahaviharn, the biggest and highest stupa in Thailand, is located as well as home to Wat Rai Khing, a famous temple among Thai people.</p><p>

an area of great historical importance that features both archaeological and religious treasures. <span class='whitespace-nowrap'>Tha Chin River</span> passes through Nakhon Pathom, which is also called the Nakhon Chaisi. the city is well known for agriculture and farming. pomelo is a famous fruit of the province, and some Thais call Nakhon Pathom the sweet pomelo town.</p>
"
      :imageUrl="require('@/assets/about/Neighborhood_01-Cover.png')"
      class="mb-18 lg:mb-20 lg:mt-8"
    />
    <tab-menu
      :items="neighborhood_types"
      all-item-label="all neighbors"
      :default-tab="0"
      path-name="neighborhoods"
      path-id="neighborhood_type"
      :has-all="true"
    />
    <div class="px-4 lg:px-8 max-w-screen-lg mx-auto mb-18 lg:mb-20">
      <TransitionGroup
        name="fade"
        tag="discover-item"
        class="grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-x-8 lg:gap-y-10"
      >
        <discover-item
          v-for="(item, index) in neighborhood_list"
          :key="index"
          :image-url="
            getImageByFormat(item.attributes.cardImage || item.attributes.image)
          "
          :item-title="item.attributes.title"
          :item-description="item.attributes.description"
          :badge="item.attributes.type.data.attributes.name"
          :item-detail-path="`/about/neighborhood/${item.attributes.slug}`"
          :isPin="item.attributes.pin"
        />
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import {
  getNeighborhoodType,
  getNeighborhoodList,
} from "@/api/endpoints/neighborhoods";
import to from "await-to-js";
import TabMenu from "@/components/TabMenu.vue";
import HeroWithImage from "@/components/HeroWithImage.vue";
import DiscoverItem from "@/components/DiscoverItem.vue";
import { getImageUrl } from "@/utils/imageUtils.js";

export default {
  name: "Neighborhood",
  components: {
    TabMenu,
    HeroWithImage,
    DiscoverItem,
  },
  created() {
    const title = "experience your own journey in Nakhon Pathom";
    const description =
      "Nakhon Pathom is one of the oldest cities and the center of civilization in the Dvaravati Kingdom. a city that is rich in history with more than 100 temples, for example, home to the royal temple, Wat Phra Pathom Chedi Rachavaramahaviharn, the biggest and highest stupa in Thailand, is located as well as home to Wat Rai Khing, a famous temple among Thai people. an area of great historical importance that features both archaeological and religious treasures. Tha Chin River passes through Nakhon Pathom, which is also called the Nakhon Chaisi. the city is well known for agriculture and farming. pomelo is a famous fruit of the province, and some Thais call Nakhon Pathom the sweet pomelo town.";
    // const titleEl = document.querySelector("head title");
    const mTitleEl = document.querySelector('head meta[name="title"]');
    const descEl = document.querySelector('head meta[name="description"]');
    const twTitleEl = document.querySelector('head meta[name="twitter:title"]');
    const twDescEl = document.querySelector(
      'head meta[name="twitter:description"]'
    );
    const ogTitleEl = document.querySelector('head meta[property="og:title"]');
    const ogDescEl = document.querySelector('head meta[property="og:description"]');
    // titleEl.textContent = title;
    mTitleEl.setAttribute("content", title);
    twTitleEl.setAttribute("content", title);
    ogTitleEl.setAttribute("content", title);
    descEl.setAttribute("content", description);
    twDescEl.setAttribute("content", description);
    ogDescEl.setAttribute("content", description);
  },
  data() {
    return {
      lang: "en",
      defaultTabId: 0,
      neighborhood_type_id: 0,
      neighborhood_types: [],
      neighborhood_list: [],
      all_neighborhood_list: [],
    };
  },
  watch: {
    "$route.query.neighborhood_type": async function (neighborhood_type_id) {
      if (neighborhood_type_id) {
        this.neighborhood_list = this.all_neighborhood_list.filter(
          (i) => i.attributes.type.data.id == neighborhood_type_id
        );
      } else {
        this.neighborhood_list = this.all_neighborhood_list;
      }
    },
  },
  mounted() {
    if (this.$route.query.neighborhood_type)
      this.neighborhood_type_id = this.$route.query.neighborhood_type;
    this.setNeighborhoodType(this.lang);
    this.setNeighborhoodList(this.neighborhood_type_id, this.lang);
  },
  methods: {
    getImageByFormat(image) {
      if (image.data) return getImageUrl(image.data);
      return "";
    },
    async setNeighborhoodType(lang) {
      const [err, response] = await to(getNeighborhoodType(lang));

      if (err) {
        return { errorMessage: err?.response?.data?.error || err.message };
      }

      this.neighborhood_types = response.data.data;
      if (this.defaultTabId == 0 && this.neighborhood_types.length > 0)
        this.defaultTabId = this.neighborhood_types[0].id;
    },
    async setNeighborhoodList(typeId, lang) {
      const [err, response] = await to(getNeighborhoodList(typeId, lang));

      if (err) {
        return { errorMessage: err?.response?.data?.error || err.message };
      }
      this.all_neighborhood_list = response.data.data;
      this.neighborhood_list = this.all_neighborhood_list;
    },
  },
};
</script>

<style scoped>
.title-para {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.discover-more {
  background: #5c623f;
  color: white;
}

.description-special-neighborhood {
  font-style: normal;
  font-weight: 300;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
