import apiClient from "@/api/client";

export const getNeighborhoodType = (lang) => {
  return apiClient.request({
    method: "GET",
    url: `api/neighborhood-types?locale=${lang}`,
  });
};

export const getNeighborhoodList = (type_id,lang) => {
  let filter = type_id > 0 ? `&filters[type][id]=${type_id}` : ''
  return apiClient.request({
    method: "GET",
    url: `api/neighborhoods?populate[0]=image&populate[1]=type&populate[2]=cardImage&locale=${lang}${filter}&pagination[pageSize]=100&sort[0]=id:desc`,
  });
};

export const getOtherNeighborhoodList = (type_id,lang) => {
  let filter = type_id > 0 ? `&filters[type][id]=${type_id}` : ''
  return apiClient.request({
    method: "GET",
    url: `api/neighborhoods?populate[0]=image&populate[1]=type&populate[2]=cardImage&sort[0]=id:desc&pagination[pageSize]=100&locale=${lang}${filter}`,
  });
};

export const getNeighborhoodById = (lang, neighborhoodId) => {
  return apiClient.request({
    method: "GET",
    url: `api/neighborhoods/${neighborhoodId}?populate=type,metadata,image,body.images&pagination[pageSize]=100&locale=${lang}`,
  });
};

export const getNeighborhoodBySlug = (lang, slug) => {
  return apiClient.request({
    method: "GET",
    url: `api/neighborhoods?filters[slug]=${slug}&populate=type,metadata,image,body.images&pagination[pageSize]=100&locale=${lang}`,
  });
};
