<template>
  <TransitionGroup name="fade" tag="div" class="relative">
    <div
      @click="scrollLeft"
      class="pointer-events-auto absolute flex left-0 h-full justify-center items-center"
      v-if="showLeft"
    >
      <chevron-left-icon fill-color="#5C623F" :size="48" />
    </div>

    <div
      @click="scrollRight"
      class="pointer-events-auto absolute flex right-0 h-full justify-center items-center"
      v-if="showRight"
    >
      <chevron-right-icon fill-color="#5C623F" :size="48" />
    </div>

    <div
      ref="tabMenu"
      class="flex w-screen overflow-x-auto space-x-8 px-4 py-8 lg:px-32 tab-menu"
    >
      <div class="flex gap-x-8 lg:gap-x-16 mx-auto">
        <div v-if="hasAll">
          <router-link
            :to="`/${pathName}`"
            :class="{
              hoverable: !isHovering && !contentId,
              active: !contentId,
            }"
            class="robototext text-np-base text-np-green block py-2 hover:text-np-glimpse"
            @mouseover="mouseOver(0)"
            @mouseleave="mouseLeave"
            >{{ allItemLabel }}
            <div
              v-if="isHovering == 0 || (!isHovering && 0 == contentId)"
              class="active-line"
            ></div>
          </router-link>
        </div>
        <div v-for="(item, index) in items" :key="index">
          <router-link
            :to="`/${pathName}?${pathId}=${item.id}`"
            :class="{
              hoverable: isHovering == null && item.id == contentId,
              active: item.id == contentId,
            }"
            class="robototext text-np-base text-np-green block py-2 whitespace-nowrap hover:text-np-glimpse"
            @mouseover="mouseOver(item.id)"
            @mouseleave="mouseLeave"
            >{{
              item.attributes && item.attributes.name
                ? item.attributes.name
                : item.name
            }}
            <div
              v-if="
                isHovering == item.id ||
                (isHovering == null && item.id == contentId)
              "
              class="active-line"
            ></div>
          </router-link>
        </div>
      </div>
    </div>
  </TransitionGroup>
</template>

<script>
import { ref } from "vue";
import ChevronLeftIcon from "vue-material-design-icons/ChevronLeft.vue";
import ChevronRightIcon from "vue-material-design-icons/ChevronRight.vue";

export default {
  name: "TabMenu",
  props: {
    hasAll: {
      type: [Boolean],
      default: false,
    },
    items: {
      type: [Array],
    },
    pathName: {
      type: [String],
    },
    pathId: {
      type: [String],
      default: "",
    },
    defaultTab: {
      type: [Number],
      default: 0,
    },
    allItemLabel: {
      type: [String],
      default: "",
    },
  },
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  setup() {
    const tabMenu = ref(null);
    const showLeft = ref(false);
    const showRight = ref(false);
    const isHover = ref(null);

    return {
      tabMenu,
      showLeft,
      showRight,
      isHover,
    };
  },
  mounted() {
    var element = this.tabMenu;

    setTimeout(() => {
      this.onScroll();
    }, 1000);

    if (element) element.addEventListener("scroll", this.onScroll);
  },
  unmounted() {
    var element = this.tabMenu;
    if (element) element.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    mouseOver(id) {
      this.isHover = id;
    },
    mouseLeave() {
      this.isHover = null;
    },
    onScroll() {
      var element = this.tabMenu;
      if (element.scrollLeft > 0) {
        this.showLeft = true;
      } else {
        this.showLeft = false;
      }

      if (
        element.offsetWidth < element.scrollWidth &&
        element.clientWidth + element.scrollLeft < element.scrollWidth
      ) {
        this.showRight = true;
      } else {
        this.showRight = false;
      }
    },
    scrollLeft() {
      var element = this.tabMenu;
      element.scroll({
        left: 0,
        behavior: "smooth",
      });
    },
    scrollRight() {
      var element = this.tabMenu;
      element.scroll({
        left: element.scrollWidth,
        behavior: "smooth",
      });
    },
  },
  computed: {
    contentId() {
      if (!this.$route.query[this.pathId]) return this.defaultTab;
      return this.$route.query[this.pathId];
    },
    isOverflowing() {
      var element = this.tabMenu;
      if (this.tabMenu) {
        let isOverflow = element.offsetWidth < element.scrollWidth;
        return isOverflow;
      } else {
        return false;
      }
    },
    isHovering() {
      return this.isHover;
    },
  },
};
</script>

<style scoped>
.menu {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 300;
  color: #5c623f;
}

.tab-menu {
  border-top: 1px solid #cecdc8;
}

.hoverable {
  color: rgba(153, 78, 46, 1);
  position: relative;
}

.active {
  font-family: "Roboto Medium", Arial, sans-serif !important;
  font-weight: 700;
}

.text-np-glimpse {
  width: 50%;
  min-width: 20px;
  height: 1px;
  margin: 0px auto;
  margin-top: 12px;
  border-bottom: 1px solid #994e2e;
}

.active-line {
  width: 50%;
  min-width: 20px;
  height: 1px;
  margin: 0px auto;
  margin-top: 12px;
  border-bottom: 1px solid #994e2e;
}
</style>
