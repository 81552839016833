function getImageUrl(image) {
    if(image.attributes.formats.large)
        return image.attributes.formats.large.url
    if(image.attributes.formats.medium)
        return image.attributes.formats.medium.url
    if(image.attributes.formats.small)
        return image.attributes.formats.small.url
    
    return image.attributes.url
}

export { getImageUrl }