<template>
  <router-link :to="itemDetailPath">
    <div class="flex flex-col justify-center w-full space-y-4">
      <!-- <div class="card-height bg-np-green rounded-tr-neera"> -->
      <div
        class="relative w-full aspect-4-3 rounded-tr-np-lg lg:rounded-tr-neera overflow-hidden"
      >
        <!-- <img class="card-content h-full w-full object-cover rounded-tr-neera" :src="imageUrl" /> -->
        <img class="absolute object-cover w-full h-full" :src="imageUrl" />
        <div v-if="isPin" class="pin">
          <div class="flex bg-np-glimpse text-white text-base py-2 px-4">
            NEW!
          </div>
        </div>
        <!-- <div class="card-button w-full">
          <button class="flex discover-more w-full h-full py-2 justify-center">
            discover more >
          </button>
        </div> -->
      </div>
      <div class="flex flex-col text-left space-y-2">
        <p
          class="text-np-badge text-np-green robotobold font-medium"
          v-if="badge"
        >
          {{ badge }}
        </p>
        <p class="font-light text-np-xl saoltext text-np-green">
          {{ itemTitle }}
        </p>
        <p
          class="description-special-offer text-np-green text-np-base font-light"
        >
          {{ itemDescription }}
        </p>
        <neera-anchor :linkTo="itemDetailPath" class="text-np-base">
          discover more
        </neera-anchor>
      </div>
    </div>
  </router-link>
</template>

<script>
import NeeraAnchor from "./NeeraAnchor.vue";

export default {
  name: "DiscoverItem",
  components: {
    NeeraAnchor,
  },
  props: {
    itemId: {
      type: [Number],
      default: 0,
    },
    imageUrl: {
      type: [String],
      default: "",
    },
    itemTitle: {
      type: [String],
      default: "",
    },
    itemDescription: {
      type: [String],
      default: "",
    },
    badge: {
      type: [String],
      default: "",
    },
    itemDetailPath: {
      type: [String],
      default: "",
    },
    isPin: {
      type: [Boolean],
      default: false,
    },
  },
};
</script>

<style scoped>
.card-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.card-button {
  position: absolute;
  bottom: 0;
}

.pin {
  position: absolute;
  top: 0;
}

.discover-more {
  font-family: "Saol Text" !important;
  background: #5c623f;
  color: white;
}

.card-height {
  position: relative;
  padding-top: 140%;
}

.description-special-offer {
  font-style: normal;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 50px;
}

.title {
  font-family: "Saol Text" !important;
  color: #5c623f;
}

.badge {
  color: #994e2e;
}
</style>
