<template>
  <div
    class="grid grid-cols-1 lg:grid-cols-2 gap-y-10 lg:gap-x-16 px-4 lg:px-8 max-w-screen-lg mx-auto"
  >
    <div class="flex flex-col w-full lg:w-4/5 text-left space-y-4">
      <p
        class="neera-text-glimps saolitalic text-np-48 lg:text-np-3xl"
        v-html="title"
      ></p>
      <p v-if="neeraBadge" class="subtitle text-np-xl saoltext">
        &mdash; neera retreat hotel
      </p>
      <p
        v-if="preDescription"
        class="robototext text-np-green font-light text-np-xl"
        v-html="preDescription"
      ></p>
      <p
        class="robototext text-np-green font-light text-np-base space-y-4"
        v-html="description"
      ></p>
      <a
        v-if="exploreLink"
        :href="exploreLink"
        class="flex flex-row saoltext neera-text-glimps"
        ><div class="flex w-auto items-left pr-2">
          <p class="saoltext">explore</p>
          <div class="flex transition duration-200 anchor-arrow px-2">
            <img src="@/assets/neera-arrow.svg" />
          </div>
        </div>
        <!-- <div class="flex w-auto items-center">
          <svg
            width="16"
            height="16"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.2656 11.6406L25.9375 11.3125C25.7031 11.0781 25.375 11.0781 25.1406 11.3125L16 20.4531L6.8125 11.3125C6.57812 11.0781 6.25 11.0781 6.01562 11.3125L5.6875 11.6406C5.45312 11.875 5.45312 12.2031 5.6875 12.4375L15.5781 22.3281C15.8125 22.5625 16.1406 22.5625 16.375 22.3281L26.2656 12.4375C26.5 12.2031 26.5 11.875 26.2656 11.6406Z"
              fill="#994e2e"
            />
          </svg>
        </div> -->
      </a>
    </div>
    <div class="justify-center order-first lg:order-none">
      <div class="relative w-full aspect-4-3 rounded-tr-np-lg overflow-hidden">
        <img class="absolute object-cover w-full h-full" :src="imageUrl" />
      </div>
      <!-- <img
        class="object-cover w-full max-h-screen lg:rounded-tr-neera"
        :src="imageUrl"
      /> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "HeroWithImage",
  props: {
    title: {
      type: [String],
    },
    description: {
      type: [String],
    },
    exploreLink: {
      type: [String],
    },
    imageUrl: {
      type: [String],
    },
    neeraBadge: {
      type: [Boolean],
      default: false,
    },
    preDescription: {
      type: [String],
      default: "",
    },
    imageOrder: {},
  },
};
</script>
<style scoped>
.title-italic {
  font-family: "Saol Italic" !important;
  font-style: italic;
  font-weight: 300;
}

.title {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #905235;
}

button {
  font-family: "Saol Text" !important;
  color: white;
  background: #994e2e;
  border-radius: 10px;
}

.underline {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
}

.subtitle {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #994e2e;
}

a:hover .anchor-arrow {
  transform: translateX(0.25rem);
}

/* p {
  white-space: break-spaces;
} */
</style>
